<template>
	<div class="pui-form">
		<mimdg-modals :modelName="modelName"></mimdg-modals>
		<!-- <pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<mimdgformheader :modelPk="modelPk"></mimdgformheader>
		</pui-form-header> -->
		<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary" show-arrows>
			<v-tab :key="0" :href="'#maintab'">{{ $t('mimdg.maintab') }}</v-tab>
			<v-tab :key="1" :href="'#documentos'" v-if="!isCreatingElement && !isModalDialog">{{ $t('pui9.documents.tab') }}</v-tab>
		</v-tabs>

		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'"
					><v-layout wrap class="pui-form-layout">
						<v-flex xs12 md12>
							<pui-field-set :title="getTitleDatos">
								<v-layout wrap>
									<!-- IDIMDG -->
									<v-flex xs12 md1>
										<pui-text-field
											:id="`idimdg-mimdg`"
											v-model="model.idimdg"
											:label="$t('mimdg.idimdg')"
											disabled
											toplabel
											maxlength="10"
										></pui-text-field>
									</v-flex>
									<!-- CLASE -->
									<v-flex xs12 md3>
										<pui-select
											id="idclase"
											attach="idclase"
											:label="$t('mimdg.clase')"
											toplabel
											clearable
											required
											:disabled="formDisabled"
											v-model="model"
											modelName="vlupmclase"
											:itemsToSelect="[{ id: model.idclase }]"
											:modelFormMapping="{ id: 'idclase' }"
											itemValue="id"
											:itemText="item => `${item.codigo} - ${item.descripcion}`"
											:order="{ id: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- NOMBRE -->
									<v-flex xs12 md12>
										<pui-text-field
											:id="`nombre-mimdg`"
											v-model="model.nombre"
											:label="$t('mimdg.nombre')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="500"
										></pui-text-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- VARIACION -->
									<v-flex xs12 md2>
										<pui-text-field
											:id="`variacion-mimdg`"
											v-model="model.variacion"
											:label="$t('mimdg.variacion')"
											:disabled="formDisabled"
											toplabel
											required
											maxlength="7"
										></pui-text-field>
									</v-flex>
									<!-- VERSION -->
									<v-flex xs12 md4 lg3>
										<pui-select
											id="idrevision"
											attach="idrevision"
											:label="$t('mimdg.idrevision')"
											toplabel
											clearable
											required
											:disabled="formDisabled"
											v-model="model"
											modelName="vlupmimdgrevision"
											:itemsToSelect="[{ id: model.idrevision }]"
											:modelFormMapping="{ id: 'idrevision' }"
											itemValue="id"
											:itemText="item => `${item.version} - ${item.name}`"
											:order="{ id: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- ONU -->
									<v-flex xs12 md2>
										<pui-text-field
											:id="`onu-mimdg`"
											v-model="model.onu"
											:label="$t('mimdg.onu')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="4"
										></pui-text-field>
									</v-flex>
									<!-- PUNINFLAMACION -->
									<v-flex xs12 md2>
										<pui-text-field
											:id="`puninflamacion-mimdg`"
											v-model="model.puninflamacion"
											:label="$t('mimdg.puninflamacion')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-text-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- IDGRUENV -->
									<v-flex xs12 md2>
										<pui-select
											id="idgruenv"
											attach="idgruenv"
											:label="$t('mimdg.idgruenv')"
											toplabel
											clearable
											:disabled="formDisabled"
											v-model="model"
											modelName="vlupmgrupoenvasado"
											:itemsToSelect="[{ codigo: model.idgruenv }]"
											:modelFormMapping="{ codigo: 'idgruenv' }"
											itemValue="codigo"
											itemText="descripcion"
											:order="{ descripcion: 'asc' }"
										></pui-select>
									</v-flex>
									<!-- NUMEMS -->
									<v-flex xs12 md2>
										<pui-text-field
											:id="`numems-mimdg`"
											v-model="model.numems"
											:label="$t('mimdg.numems')"
											:disabled="formDisabled"
											toplabel
											maxlength="6"
										></pui-text-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- CONTMAR -->
									<v-flex xs12 md2>
										<pui-checkbox
											:label="$t('mimdg.contmar')"
											v-model="model.contmar"
											true-value="1"
											false-value="0"
											:disabled="formDisabled"
										></pui-checkbox>
									</v-flex>
									<!-- ARTICULO15 -->
									<v-flex xs12 md2>
										<pui-checkbox
											:label="$t('mimdg.articulo15')"
											v-model="model.articulo15"
											true-value="1"
											false-value="0"
											:disabled="formDisabled"
										></pui-checkbox>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- CLASERIESSECUNDARIO1 -->
									<v-flex xs12 md2>
										<pui-select
											id="idclaseriessecundario1"
											attach="idclaseriessecundario1"
											:label="$t('mimdg.claseriessecundario1')"
											toplabel
											clearable
											:disabled="formDisabled"
											v-model="model"
											modelName="vlupmclase"
											:itemsToSelect="[{ id: model.idclaseriessecundario1 }]"
											:modelFormMapping="{ id: 'idclaseriessecundario1' }"
											itemValue="id"
											:itemText="item => `${item.codigo} - ${item.descripcion}`"
											:order="{ id: 'asc' }"
										></pui-select>
									</v-flex>
									<!-- CLASERIESSECUNDARIO2 -->
									<v-flex xs12 md2>
										<pui-select
											id="idclaseriessecundario2"
											attach="idclaseriessecundario2"
											:label="$t('mimdg.claseriessecundario2')"
											toplabel
											clearable
											:disabled="formDisabled"
											v-model="model"
											modelName="vlupmclase"
											:itemsToSelect="[{ id: model.idclaseriessecundario2 }]"
											:modelFormMapping="{ id: 'idclaseriessecundario2' }"
											itemValue="id"
											:itemText="item => `${item.codigo} - ${item.descripcion}`"
											:order="{ id: 'asc' }"
										></pui-select>
									</v-flex>
									<!-- CLASERIESSECUNDARIO3 -->
									<v-flex xs12 md2>
										<pui-select
											id="idclaseriessecundario3"
											attach="idclaseriessecundario3"
											:label="$t('mimdg.claseriessecundario3')"
											toplabel
											clearable
											:disabled="formDisabled"
											v-model="model"
											modelName="vlupmclase"
											:itemsToSelect="[{ id: model.idclaseriessecundario3 }]"
											:modelFormMapping="{ id: 'idclaseriessecundario3' }"
											itemValue="id"
											:itemText="item => `${item.codigo} - ${item.descripcion}`"
											:order="{ id: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- FECINIVIGENCIA -->
									<v-flex xs12 md2>
										<pui-date-field
											:id="`fecinivigencia-mimdg`"
											v-model="model.fecinivigencia"
											:label="$t('mimdg.fecinivigencia')"
											:disabled="formDisabled"
											toplabel
											time
											required
										></pui-date-field>
									</v-flex>
									<!-- FECFINVIGENCIA -->
									<v-flex xs12 md2>
										<pui-date-field
											:id="`fecfinvigencia-mimdg`"
											v-model="model.fecfinvigencia"
											:label="$t('mimdg.fecfinvigencia')"
											:disabled="formDisabled"
											toplabel
											time
										></pui-date-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12 md3>
										<pui-select
											id="compatibilidad"
											attach="compatibilidad"
											:label="$t('mimdg.compatibilidad')"
											toplabel
											clearable
											:disabled="formDisabled"
											v-model="model"
											modelName="vlupmcompatibilidad"
											:itemsToSelect="[{ id: model.compatibilidad }]"
											:modelFormMapping="{ id: 'compatibilidad' }"
											itemValue="id"
											:itemText="item => `${item.id} - ${item.descripcion}`"
											:order="{ id: 'asc' }"
										></pui-select>
									</v-flex>
									<v-flex xs12 md3>
										<pui-select
											id="grupo"
											attach="grupo"
											:label="$t('mimdg.grupo')"
											toplabel
											clearable
											:disabled="formDisabled"
											v-model="model"
											modelName="vlupmgrupommpp"
											:itemsToSelect="[{ grmpcodi: model.grupo }]"
											:modelFormMapping="{ grmpcodi: 'grupo' }"
											itemValue="grmpcodi"
											:itemText="item => `${item.grmpcodi} - ${item.grmpdesc}`"
											:order="{ grmpcodi: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12 md2>
										<pui-checkbox
											:label="$t('mimdg.prohibido')"
											v-model="model.prohibido"
											true-value="1"
											false-value="0"
											:disabled="formDisabled"
										></pui-checkbox>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- LIMEXPLMIN -->
									<v-flex xs12 md2>
										<pui-number-field
											:id="`limexplmin-mimdg`"
											v-model="model.limexplmin"
											:label="$t('mimdg.limexplmin')"
											:disabled="formDisabled"
											toplabel
											decimals="3"
											maxlength="18"
										></pui-number-field>
									</v-flex>
									<!-- LIMEXPMAX -->
									<v-flex xs12 md2>
										<pui-number-field
											:id="`limexpmax-mimdg`"
											v-model="model.limexpmax"
											:label="$t('mimdg.limexpmax')"
											:disabled="formDisabled"
											toplabel
											decimals="3"
											maxlength="18"
										></pui-number-field>
									</v-flex>
								</v-layout>
								<v-layout dense>
									<!-- OBSERVACION -->
									<v-flex xs12>
										<pui-text-area
											ref="observacionesmimdg"
											:id="`observaciones-mimdg`"
											:label="$t('mimdg.observaciones')"
											v-model="model.observaciones"
											toplabel
											:disabled="formDisabled"
											maxlength="300"
											rows="4"
										></pui-text-area>
									</v-flex>
								</v-layout>
							</pui-field-set>
							<pui-field-set :title="$t('mimdg.fieldset2')">
								<v-row dense>
									<v-col cols="12" md="10">
										<pui-multi-select
											id="multiSelectCategoriaEstiba"
											itemValue="idcatestiba"
											itemText="descripcion"
											v-model="model.categoriasestiba"
											:items="allCategoriaestiba(model)"
											:itemsToSelect="model.categoriasestiba"
											:disabled="formDisabled"
											:key="refreshSelect"
										></pui-multi-select>
									</v-col>
								</v-row>
							</pui-field-set>
							<!-- MINI AUDIT -->
							<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
						</v-flex>
					</v-layout>
				</v-tab-item>
				<v-tab-item :key="1" lazy :value="'documentos'">
					<pui-master-detail componentName="puidocumentgrid" :parentModelName="modelName" :parentPk="pk" >
					</pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>

		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mimdgActions from './MimdgActions';
import mimdgModals from './MimdgModals.vue';

export default {
	name: 'mimdg-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mimdg-modals': mimdgModals
	},
	data() {
		return {
			modelName: 'mimdg',
			parentModelName: '[PARENT_MODEL_NAME]',
			actions: mimdgActions.formActions,
			refreshSelect: 0
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
			this.setParentPk();

			if (!this.model.articulo15) {
				this.model.articulo15 = 0;
			}
		},
		setParentPk() {
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel) {
				//this.model.[LOCAL_FK_ATTRIBUTE] = parentModel.pk.[REMOTE_FK_ATTRIBUTE];
			}
		},
		allCategoriaestiba(model) {
			if (!this.$appUtils.isEmpty(model.allcategoriasestiba)) return model.allcategoriasestiba;

			const params = {};

			const url = this.$store.getters.getModelByName(this.modelName).url.getCategoriasEstiba;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('mimdg.getCategoriasEstiba.title');
			this.$puiRequests.getRequest(
				url,
				params,
				response => {
					// Valid response, do code and close modal
					model.allcategoriasestiba = response.data.allcategoriasestiba;
					this.refreshSelect++;
				},
				e => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message, title);
				}
			);
		}
	},
	computed: {
		getTitleDatos() {
			return this.$t('form.mimdg');
		}
	},
	created() {}
};
</script>
